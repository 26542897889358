import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  // Label,
  // Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import * as actions from "../../../store/actions/index";

import "./Published.css";

class Unpublished extends React.Component {
  state = {
    modal: false,
  };

  componentDidMount() {
    const { get_writings } = this.props;
    get_writings();
  }

  onClickRestore = (writing) => {
    writing.delete = writing.delete === "1" ? 0 : 1;
    const { update_writing } = this.props;
    update_writing(writing);
  };

  onClickDelete = (writing) => {
    this.setState({
      delete_writing: writing,
      modal: true,
    });
  };

  onClickEdit = (writing) => {
    // console.log("edit", this.props);
    const { push } = this.props.history;
    push({
      pathname: "/admin/published/update",
      writing,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_writing } = this.state;
    const { delete_writing_fn } = this.props;
    delete_writing_fn(delete_writing);
    this.setState({
      delete_writing: null,
      modal: false,
    });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_writing: null,
      modal: false,
    });
  };

  render() {
    const { writings } = this.props;
    const writings_1 = writings.filter((item) => {
      return item.delete === "1";
    });
    console.log(writings);
    return (
      <>
        <div className="admin-images-list-main">
          <div className="admin-header">
            Unpublished writings <span className="total-detail"></span>
          </div>
          <Row className="mt-5" id="admin-list-images-row">
            <Col xl="2" className="admin-list-image-col col-header">
              Title
            </Col>
            <Col xl="4" className="admin-list-image-col col-header">
              Content
            </Col>
            <Col xl="2" className="admin-list-image-col col-header">
              Modified at
            </Col>
            <Col xl="1" className="admin-list-image-col col-header"></Col>
            <Col xl="1" className="admin-list-image-col col-header"></Col>
            <Col xl="1" className="admin-list-image-col col-header"></Col>
            <Col xl="12">
              <hr />
            </Col>
            {writings_1.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <Col xl="2" className="admin-list-image-col">
                    {item.title}
                  </Col>
                  <Col xl="4" className="admin-list-image-col">
                    {item.body}
                  </Col>
                  <Col xl="2" className="admin-list-image-col">
                    {item.modified_at}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      onClick={() => this.onClickEdit(item)}
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/writings_admin_dc9d02f44d8abdb53a8c4bec400c42c6e39f848bdf057996122dd2cbc5884194/${item.category_id}/${item.id}`}
                    >
                      view
                    </Link>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      onClick={() => this.onClickRestore(item)}
                    >
                      Restore
                    </Button>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      // onClick={() => this.onChangeDeleteSubmit(post)}
                      onClick={() => this.onClickDelete(item)}
                    >
                      Delete
                    </Button>
                  </Col>
                </React.Fragment>
              );
            })}
            {writings_1.length === 0 && (
              <div>There is no unpublished writing</div>
            )}
          </Row>
          <Modal isOpen={this.state.modal}>
            <ModalHeader>Confirm Delete</ModalHeader>
            <ModalBody>Are you sure?</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.onClickDeleteConfirm}>
                Delete
              </Button>{" "}
              <Button color="secondary" onClick={this.onClickDeleteCancel}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // news: state.news.news,
    writings: state.writing.writings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writings: () => dispatch(actions.get_writings()),
    update_writing: (payload) => dispatch(actions.update_writing(payload)),
    delete_writing_fn: (payload) => dispatch(actions.delete_writing(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unpublished);
